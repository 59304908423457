import React from 'react';

import { S3_IMAGES } from '../../../utils/constants';
import Slider from 'react-slick';
import { useTranslation } from 'react-i18next';
import { Disclosure } from '@headlessui/react';
import { StaticImage } from 'gatsby-plugin-image';

const logosPartners = [
  `${S3_IMAGES}/denverzoo.png`,
  `${S3_IMAGES}/nationalwestern.png`,
  `${S3_IMAGES}/cheyenne.png`,
  `${S3_IMAGES}/mountain.png`,
  `${S3_IMAGES}/paralympic.png`,
  `${S3_IMAGES}/ssa.png`,
  `${S3_IMAGES}/aramark.png`,
  `${S3_IMAGES}/rockies.png`,
  `${S3_IMAGES}/dcpa.png`,
  `${S3_IMAGES}/du.png`,
  `${S3_IMAGES}/redrocks.png`,
];

const settings = {
  dots: true,
  infinite: true,
  arrows: false,
  slidesToShow: 3,
  slidesToScroll: 3,
  variableWidth: true,
  autoplay: false,
  speed: 500,
  autoplaySpeed: 2000,
  cssEase: 'linear',
  responsive: [
    {
      breakpoint: 600,
      settings: {
        variableWidth: true,
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const FAQ = () => {
  const [t] = useTranslation('pros');

  const faq = [
    {
      label: t('faq1'),
      description: t('description1'),
    },
    {
      label: t('faq2'),
      description: t('description2'),
    },
    {
      label: t('faq3'),
      description: t('description3'),
    },
    {
      label: t('faq4'),
      description: t('description4'),
    },
    {
      label: t('faq5'),
      description: t('description5'),
    },
    {
      label: t('faq6'),
      description: t('description6'),
    },
  ];

  return (
    <div>
      <h2 className="mt-20 text-4xl text-primary font-bold text-center">{t('plenty')}</h2>
      <p className="mt-11 text-center mb-14">{t('plentyDescription')}</p>

      <div className="tablet:px-20 px-4">
        <Slider {...settings}>
          {logosPartners.map((item, index) => (
            <div key={index}>
              <img key={index} className="h-[200px] p-8 " src={item} alt="" />
            </div>
          ))}
        </Slider>

        <br />
      </div>

      <div className="my-16 tablet:px-12 px-4 justify-between flex">
        <div className="w-[40%] hidden tablet:block">
          <StaticImage src={'../../images/fillingSection/phone.png'} alt="Cellphone" />
        </div>
        <div className="tablet:w-[50%] w-full pb-8 bg-blue-200 rounded-xl box-border px-[23px]">
          <h2 className="mt-[53px] mb-[30px]  text-4xl text-primary font-bold text-center">FAQs</h2>
          {faq.map((v, key) => (
            <Disclosure key={key}>
              {({ open }) => (
                <>
                  <Disclosure.Button className="flex mt-2 w-full justify-between rounded-lg bg-blue-100 px-4 py-2 text-left text-sm font-medium text-purple-900 hover:bg-purple-200 focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75">
                    <span className="text-[16px]">{v.label}</span>
                    {/* <ChevronUpIcon
                    className={`${open ? 'rotate-180 transform' : ''} h-5 w-5 text-purple-500`}
                  /> */}
                  </Disclosure.Button>
                  <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-primary bg-blue-100 rounded-xl mt-2 mb-2">
                    {v.description}
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FAQ;
