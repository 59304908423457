import React from 'react';

interface ITitle {
  text: string;
}

const Title = ({ text }: ITitle) => {
  return (
    <h1 className="text-primary tablet:text-4xl text-xl tablet:text-left text-center font-bold">
      {text}
    </h1>
  );
};

export default Title;
