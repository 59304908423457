import React from 'react';
import Header from '../components/Header';
import { graphql } from 'gatsby';
import ProsBody from '../components/Pros/Hero';
import Draws from '../components/Pros/Draws';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Kind from '../components/Pros/Kind';
import FAQ from '../components/Pros/FAQ';
import Footer from '../components/Footer';

const ProsPage = () => {
  return (
    <>
      <div
        style={{
          background:
            'linear-gradient(317deg, rgba(0, 33, 76, 0.67) -1.08%, rgba(55, 125, 215, 0.49) 18.04%, rgba(78, 147, 237, 0.36) 34.01%, #E0ECF7 49.2%)',
        }}
      >
        <Header hasBackground />
        <ProsBody />
      </div>
      <Draws />
      <Kind />
      <FAQ />
      <Footer />
    </>
  );
};

export default ProsPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
