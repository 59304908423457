import React from 'react';
import Slider from 'react-slick';
// Import css files
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { S3_IMAGES } from '../../../utils/constants';
import { useTranslation } from 'react-i18next';

const Kind = () => {
  const [t] = useTranslation('pros');

  const settings = {
    dots: true,
    infinite: true,
    arrows: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <div className="bg-bodyKind tablet:py-[97px] tablet:px-28 px-10 py-10">
      <div className="flex flex-wrap justify-end flex-col-reverse tablet:flex-row">
        <div className="tablet:w-[40%] w-full">
          <div className="tablet:w-[70%] w-full">
            <Slider {...settings}>
              <div>
                <div className="flex flex-wrap justify-center">
                  <div className="w-[147px] h-[158.8px] bg-blue-200 rounded-3xl m-3 flex flex-col items-center justify-center">
                    <img className="w-[55px] h-[55px]" src={`${S3_IMAGES}/concession.svg`} />
                    <p className="text-primary">Consessions</p>
                  </div>
                  <div className="w-[147px] h-[158.8px] bg-blue-200 rounded-3xl m-3 flex flex-col items-center justify-center">
                    <img className="w-[55px] h-[55px]" src={`${S3_IMAGES}/general-labor.svg`} />
                    <p className="text-primary">General labor</p>
                  </div>
                  <br />
                  <div className="w-[147px] h-[158.8px] bg-blue-200 rounded-3xl m-3 flex flex-col items-center justify-center">
                    <img className="w-[55px] h-[55px]" src={`${S3_IMAGES}/line-cook.svg`} />
                    <p className="text-primary">Line cook</p>
                  </div>
                  <div className="w-[147px] h-[158.8px] bg-blue-200 rounded-3xl m-3 flex flex-col items-center justify-center">
                    <img className="w-[55px] h-[55px]" src={`${S3_IMAGES}/dishwasher.svg`} />
                    <p className="text-primary">Dishwasher</p>
                  </div>
                </div>
              </div>
              <div>
                <div className="flex flex-wrap justify-center">
                  <div className="w-[147px] h-[158.8px] bg-blue-200 rounded-3xl m-3 flex flex-col items-center justify-center">
                    <img className="w-[55px] h-[55px]" src={`${S3_IMAGES}/cleaner.svg`} />
                    <p className="text-primary">Cleaner</p>
                  </div>
                  <div className="w-[147px] h-[158.8px] bg-blue-200 rounded-3xl m-3 flex flex-col items-center justify-center">
                    <img className="w-[55px] h-[55px]" src={`${S3_IMAGES}/bartender.svg`} />
                    <p className="text-primary">Bartender</p>
                  </div>
                  <br />
                  <div className="w-[147px] h-[158.8px] bg-blue-200 rounded-3xl m-3 flex flex-col items-center justify-center">
                    <img className="w-[55px] h-[55px]" src={`${S3_IMAGES}/bussier.svg`} />
                    <p className="text-primary">Bussier</p>
                  </div>
                  <div className="w-[147px] h-[158.8px] bg-blue-200 rounded-3xl m-3 flex flex-col items-center justify-center">
                    <img className="w-[55px] h-[55px]" src={`${S3_IMAGES}/prep-cook.svg`} />
                    <p className="text-primary">Prep cook</p>
                  </div>
                </div>
              </div>
              <div>
                <div className="flex flex-wrap justify-center">
                  <div className="w-[147px] h-[158.8px] bg-blue-200 rounded-3xl m-3 flex flex-col items-center justify-center">
                    <img className="w-[55px] h-[55px]" src={`${S3_IMAGES}/server.svg`} />
                    <p className="text-primary">Server</p>
                  </div>
                  <div className="w-[147px] h-[158.8px] bg-blue-200 rounded-3xl m-3 flex flex-col items-center justify-center">
                    <img className="w-[55px] h-[55px]" src={`${S3_IMAGES}/cashier.svg`} />
                    <p className="text-primary">Cashier</p>
                  </div>
                </div>
              </div>
            </Slider>
          </div>
        </div>
        <div className="tablet:w-[50%] w-full flex flex-col justify-center">
          <h2 className="text-4xl text-primary font-bold">{t('kindTitle')}</h2>
          <p className="text-2xl mt-7">{t('kindDescription')}</p>
        </div>
      </div>
    </div>
  );
};

export default Kind;
