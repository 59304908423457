import React from 'react';
import Slider, { Settings } from 'react-slick';
import { S3_IMAGES } from '../../../utils/constants';
import { useTranslation } from 'react-i18next';
import useWindowDimensions from '../../../hooks/useWindows';

const Draws = () => {
  const [t] = useTranslation('pros');
  const { width } = useWindowDimensions();

  const settings: Settings = {
    dots: true,
    arrows: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1240,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="mt-11 tablet:ml-11">
      <div
        style={{ backgroundPositionY: 'inherit' }}
        className="bg-bodyDraws bg-no-repeat bg-cover bg-center"
      >
        <h2 className="text-4xl text-primary font-bold text-center">{t('workingSimple')}</h2>
        <div className="my-11 block tablet:hidden">
          <Slider {...settings}>
            <div className="relative">
              <div
                style={{ bottom: 30, left: width >= 800 ? 20 : 123 }}
                className="absolute max-w-[195px] bg-primaryB-300 text-white rounded-2xl p-[20px]"
              >
                <h6 className="text-base">{t('titleCardSimple')}</h6>
                <p className="text-[10px]">{t('cardSimple')}</p>
              </div>
              <div className="text-center">
                <img className="m-auto" src={`${S3_IMAGES}/phone1.png`} />
              </div>
            </div>
            <div>
              <div className="flex justify-center">
                <img src={`${S3_IMAGES}/phone2.png`} />
              </div>
            </div>
            <div className="relative">
              <div
                style={{ bottom: 30, left: width >= 800 ? 20 : 130 }}
                className="absolute max-w-[195px] bg-primaryB-300 text-white rounded-2xl p-[20px]"
              >
                <h6 className="text-base">{t('titleScanCard')}</h6>
                <p className="text-[10px]">{t('scanCard')} </p>
              </div>
              <div className="flex justify-center">
                <img src={`${S3_IMAGES}/phone3.png`} />
              </div>
            </div>
            <div className="relative">
              <div
                style={{ bottom: 30, left: width >= 800 ? 20 : 130 }}
                className="absolute max-w-[195px] bg-primaryB-300 text-white rounded-2xl p-[20px]"
              >
                <h6 className="text-base">{t('titleGetPaidCard')}</h6>
                <p className="text-[10px]">{t('getPaidCard')} </p>
              </div>
              <div className="flex justify-center">
                <img src={`${S3_IMAGES}/phone4.png`} />
              </div>
            </div>
          </Slider>
        </div>
        <div className="pt-20 justify-around pb-[90px] px-10 hidden tablet:flex">
          <div className="relative">
            <div
              style={{ bottom: 25, left: -40 }}
              className="absolute max-w-[195px] bg-primaryB-300 text-white rounded-2xl p-[20px]"
            >
              <h6 className="text-base">{t('titleCardSimple')}</h6>
              <p className="text-[10px]">{t('cardSimple')}</p>
            </div>
            <img src={`${S3_IMAGES}/phone1.png`} />
          </div>
          <div>
            <img src={`${S3_IMAGES}/phone2.png`} />
          </div>
          <div className="relative">
            <div
              style={{ top: -10, left: -40 }}
              className="absolute max-w-[195px] bg-primaryB-300 text-white rounded-2xl p-[20px]"
            >
              <h6 className="text-base">{t('titleScanCard')}</h6>
              <p className="text-[10px]">{t('scanCard')} </p>
            </div>
            <img src={`${S3_IMAGES}/phone3.png`} />
          </div>
          <div className="relative">
            <div
              style={{ bottom: 25, right: -60 }}
              className="absolute max-w-[195px] bg-primaryB-300 text-white rounded-2xl p-[20px]"
            >
              <h6 className="text-base">{t('titleGetPaidCard')}</h6>
              <p className="text-[10px]">{t('getPaidCard')} </p>
            </div>
            <img src={`${S3_IMAGES}/phone4.png`} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Draws;
