import React from 'react';

import { useTranslation } from 'react-i18next';
import Title from '../../Title';
import { OutboundLink } from 'gatsby-plugin-google-gtag';
import { APPLE_STORE, GOOGLE_PLAY } from '../../../utils/constants';
import { StaticImage } from 'gatsby-plugin-image';

const ProsBody = () => {
  const [t] = useTranslation('pros');

  return (
    <div>
      <div className="px-4 py-0">
        <div className="tablet:bg-heroPro bg-bodyDrawsResponsive bg-contain bg-no-repeat bg-center">
          <div className="px-9 tablet:pt-44 tablet:min-h-[74vh] max-w-[650px]">
            <Title text={t('title')} />
            <h6 className="tablet:text-2xl text-xs text-primary tablet:my-6 my-2 tablet:text-left text-center">
              {t('subtitle')}
            </h6>
            <div className="flex md:justify-start justify-center items-center mt-[25rem] tablet:mt-1 py-5">
              <OutboundLink href={GOOGLE_PLAY}>
                <StaticImage
                  className="sm:w-48 w-1/2 mr-3.5 mb-1"
                  src={'../../images/GooglePlayStore.svg'}
                  alt="play store"
                />
              </OutboundLink>
              <OutboundLink href={APPLE_STORE}>
                <StaticImage
                  className="sm:w-48 w-1/2 mb-1"
                  src={'../../images/AppStore.svg'}
                  alt="play store"
                />
              </OutboundLink>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProsBody;
